import React, { Component} from 'react'
import { connect } from 'react-redux'
import Header from '../Header'
import { key_local_storage_user } from '../../lib/backend/data'
import 'moment/locale/es';
import CotizacionEditar from '../../subComponents/cotizaciones/editar'

class EditarCotizacion extends Component {
    constructor(props){
        super(props)
        this.state = {
            id: this.props.match.params.id,
            user: JSON.parse(localStorage.getItem(key_local_storage_user))
        }
    }
    render(){
        const { user, id } = this.state
        return(
            <div className="fluid">
       <Header/>
       <div className="container-fluid">
        <div className="row">
            <main className="col-md-12 ms-sm-auto col-lg-12 px-md-4 leftauto">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center border-top pb-2 mb-3">
              </div>
              <CotizacionEditar idproducto={id} token={user.tokenSession} />
            </main>
          </div>
        </div>
      </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(EditarCotizacion);