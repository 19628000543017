import { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import data, { key_local_storage_user } from '../../lib/backend/data'
import { toast } from 'react-toastify';

const ConfiguracionSelector = (props) => {
    const {
        titulo,
        tipo,
        disable,
        url_refresh,
        subtipo
    } = props
    const [ modelos, setModelos ] = useState([])
    const [ loadingModelos, setLoadingModelos ] = useState(true)
    const user = JSON.parse(localStorage.getItem(key_local_storage_user))
    const default_value = props.default_value ? props.default_value : ''
    
    const refrescarBD = async () => {
        setLoadingModelos(true)
        return fetch(url_refresh,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingModelos(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingModelos(false)
            } else{
                toast.success("Actualizado exitosamente")
                obtenerValores()
            }
            return false
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return false
        })
    }

    const obtenerValores = async () => {
        setLoadingModelos(true)
        return fetch(`${data.urlapi}/configuracion/tipo?tipo=${tipo}&subtipo=${subtipo}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingModelos(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingModelos(false)
            } else if(Array.isArray(res) !== false){
                setModelos(res)
            }
            return setLoadingModelos(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingModelos(false)
        })
    }


    const handleChangeWareHouse = (e) => {
        const { value } = e.target
        if(props.onChange) props.onChange(value)
      }

    const botonSincronizar = () => {
        return <i onClick={() => refrescarBD()} className="text-right fas fa-sync-alt"></i>
    }
    useEffect(() => {
        obtenerValores()
    }, [])

    if(loadingModelos) return <Spinner animation='border' />
 
    return <div>
        <label className='form-control-label'>{titulo} {botonSincronizar()} </label>
        <select disabled={disable === true ? true : false } defaultValue={default_value} className='form-control' onChange={handleChangeWareHouse}>
            <option value="">Selecciona</option>
            {
                modelos.map(modelo => {
                    return <option key={modelo._id} value={modelo.valor} >{modelo.nombre}</option>
                })
            }
        </select>
    </div>

}

export default ConfiguracionSelector