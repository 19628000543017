import { useEffect, useRef, useState } from "react"
import { Card, Spinner } from "react-bootstrap"
import Skeleton from "react-loading-skeleton"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import { urlapi } from "../../lib/backend/data"
import { showAmmount } from "../../lib/helpers/helpers"

const BuscadorCodigo = (props) => {
    const abortController = useRef(null)
    const [ codigoBusqueda, setCodigoBusqueda ] = useState('')
    const [ resultadosCodigoBusqueda, setResultadosCodigoBusqueda ] = useState([])
    const [ loadingCodigos, setLoadingCodigos ] = useState(false)
    const tipo = props.tipo ? props.tipo : false

    useEffect(() => {
        const concernedElement = document.querySelector(".code-search");
        document.addEventListener("mousedown", (event) => {
            if (!concernedElement.contains(event.target)){
                setTimeout(() => {
                    setResultadosCodigoBusqueda([])
                }, 200);
            } else {
                if(codigoBusqueda){
                    if(codigoBusqueda.length > 3){
                        cancelRequest()
                        setTimeout(() => {
                            fetchCodigo(codigoBusqueda)
                        }, 100);
                    }
                }
            }
          });
    }, [])

    const fetchCodigo = (codigo_busqueda) => {
        if(!codigo_busqueda) return false
        abortController.current = new AbortController()
        setLoadingCodigos(true)
        return fetch(`${urlapi}/productos/search-code`,{
            signal: abortController.current.signal,
            method: "POST",
            body: JSON.stringify({
                codigo_busqueda: codigo_busqueda.toUpperCase()
            }),
            headers: {
                'Content-Type': 'application/json'
            }
            })
            .then(res => res.json())
            .then(resultados => {
                if(!resultados){
                    toast.error("Sin datos del servidor")
                } else if(resultados.errorMessage){
                    toast.error(resultados.errorMessage)
                } else if(Array.isArray(resultados) !== false){
                    if(props.onResultados) props.onResultados(resultados)
                    setResultadosCodigoBusqueda(resultados)
                }
                return setLoadingCodigos(false)
            })
            .catch(error => {
                return setLoadingCodigos(false)
            })
    }

    const cancelRequest = () => abortController.current && abortController.current.abort();

    const handleChangeCodigo = (e) => {
        const { value } = e.target
        if(props.onChange) props.onChange(value)
        if(value){
            if(value.length > 3){
                cancelRequest()
                fetchCodigo(value)
            }
        }
        return setCodigoBusqueda(value)
    }

    const handleSubmitCodigo = (e) => {
        e.preventDefault()
    }

    const seleccionarProducto = (pro) => {
        if(props.onSelectProduct) props.onSelectProduct(pro)
        setResultadosCodigoBusqueda([])
        setCodigoBusqueda('')
    }

    const showComponentByType = (pro, extra_string) => {
        switch (tipo) {
            case "funcion":
                return <p className='hover mb-0' onClick={() => seleccionarProducto(pro)}><b>{pro.titulo} {extra_string ? extra_string : false}</b></p>
            default:
                return <Link to={`product-edit-${pro._id}`}>{pro.titulo} {extra_string ? extra_string : false}</Link>
        }
    }

    const busquedaCodigo = () => {
        if(loadingCodigos) return <div style={{ position: "absolute", zIndex: 999, width: "100%" }}>
            <Card className='p-3'>
                <Spinner animation='border' />
                <h6 className='mb-0'>CARGANDO RESULTADOS</h6>
            </Card>
        </div>
        return false
        
        if(resultadosCodigoBusqueda.length < 1) return false

        return <div style={{ position: "absolute", zIndex: 999, width: "100%" }}>
            <Card className='p-3'>
            {
                resultadosCodigoBusqueda.map(codigo => {
                    return <div key={`${codigo._id}`}>
                        {showComponentByType(codigo, `$${showAmmount(codigo.precio)}`) }
                    </div>
                })
            }
            </Card>
        </div>
    }

    return <div>
            <input 
                className='mb-3 form-control code-search uppercase' 
                autoFocus 
                value={codigoBusqueda}
                autoComplete="off"
                name="codigo_busqueda" 
                placeholder='BUSCAR POR CÓDIGO' 
                onChange={handleChangeCodigo} />
                {busquedaCodigo()}
    </div>
}

export  default BuscadorCodigo