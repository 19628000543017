import { useEffect, useState } from 'react'
import { Row, Col, Button, Table, Card, Spinner, Form, Modal } from 'react-bootstrap'
import data, { key_local_storage_user, url_images, urlapi } from '../../../lib/backend/data'
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton'
import * as XLSX from 'xlsx'
import { corregirTelefono, formatoMoneda, sliceIntoChunks, validateEmail } from '../../../lib/helpers/helpers';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { addMoreDays, formatDateHoy } from '../../../lib/helpers/dates';
import { useDispatch, useSelector } from 'react-redux';
import { BiSolidChevronRight } from 'react-icons/bi';
import { AiOutlineFileExcel } from 'react-icons/ai';
// import SelectorClientes from '../../clientes/selector';
import { Button as Boton } from '@mui/material';
import { FaAngleLeft, FaFileUpload } from 'react-icons/fa';
import CargandoFlotante from '../../general/cargando_flotante';
import Header from '../../../components/Header';


const ProductosImportador = (props) => {
    const initialDate = {
        startDate: new Date(),
        endDate: addMoreDays(new Date(),1),
        key: 'selection',
    }
    const dispatch = useDispatch()
    const [ loadingExcel, setLoadingExcel ] = useState(false)
    const [ errores, setErrores ] = useState([])
    const [ registros, setRegistros ] = useState([])
    const [ columnasrequeridas, setColumnasRequeridas ] = useState([
        'titulo',
        'codigo',
        'codigo_proveedor',
        'codigo_fabrica',
        'codigo_importacion',
        'precio'
    ])
    const [ loadingCarga, setLoadingCarga ] = useState(false)
    const [ procesados, setProcesados ] = useState(0)
    const [ selectionRange, setSelectionRange ] = useState(initialDate)
    const [ loadingMaster, setLoadingMaster] = useState(false)
    const [ conductorSeleccionado, setConductorSeleccinoado ] = useState(false)
    const [ filtroCliente, setFiltroCliente ] = useState('')
    const [ tipoAsignacion, setTipoAsignacion ] = useState('vehiculo')
    const [ loadingOperaciones, setLoadingOperaciones] = useState(0)
    const [ duplicados, setDuplicados] = useState([])
    const [ mensajeLoading, setMensajeLoading ] = useState('')
    const [ showModalErrores, setShowModalErrores ] = useState(false)
    const [ operacionesListas, setOperacionesListas] = useState(0)
    const session = useSelector(state => state.miusuario)
    const [ omitirEstados, setOmitirEstados ] = useState(false)
    const [ formatoSeleccionado, setFormatoSeleccionado ] = useState(false)
    const imgExcel = `${url_images}/animations/Man transfer data from cloud.gif`
    const user = JSON.parse(localStorage.getItem(key_local_storage_user))
    const token = user?.tokenSession ? user?.tokenSession : false


    const cargandoScreen = () => {
        return <Row>
            <Col md={4}><Skeleton count={3} /></Col>
                <Col md={4}><Skeleton count={3} /></Col>
                <Col md={4}><Skeleton count={3} /></Col>
            </Row>
    }

    const validarCodigosItems= async (codigos) => {
        const data_default = codigos.map(co => ({ codigo: co, valido: false }))
        return fetch(`${data.urlapi}/productos/validar/codigos`,{
            method:'POST',
            body: JSON.stringify({
                codigos
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return false
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return false
            } else if(Array.isArray(res) !== false){
                return res
            }
            return data_default
        })
        .catch(error => {
            toast.error(error.message)
            return data_default
        })
    }


    const crearProductosMasivo = async (array) => {
    const progreso = procesados + registros.length
    const agregar_datos = filtroCliente ? array.map(reg => ({ ...reg, cliente: filtroCliente })) : array

    return fetch(`${data.urlapi}/productos/batch`,{
        method:'POST',
        body: JSON.stringify({
           productos: agregar_datos
        }),
        headers: { 
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${token}`
        }
    })
    .then(res => {
        if(res.status === 401) return window.location = '/login'
        return res.json()
    })
    .then(res => {
        if(!res){
            return toast.error('Sin datos')
        } else if(res.errorMessage){
            return toast.error(res.errorMessage)
        }
        if(Array.isArray(res.invalidos) !== false){
            for( const error of res.invalidos ){
                    errores.push(error)
            }
            setErrores(prev => [...[], ...errores])
        }
        return setProcesados(progreso)
    })
    .catch(error => {
        toast.error(error.message)
        return setProcesados(progreso)
    })
}




    const importacionExitosa = () => {
        if(props.onDatosImportados) return props.onDatosImportados()
        return true
    }

    const subirRegistros = async () => {
        if(registros.length < 1) return toast.error("No hay registros para importar")
        setErrores([...[], ...[]])
        setLoadingCarga(true)

        setMensajeLoading('Validando Codigos')
        const validar_referencias = await validarCodigos(registros)

        if(Array.isArray(validar_referencias) !== true ){
            setLoadingCarga(false)
            if (validar_referencias[0].valido) return toast.error("este codigo ya existe") 
            return 
        }
        
        if(validar_referencias.length > 0){
            if(omitirEstados === true){
                for( const orden of validar_referencias ){
                    const { referencia } = orden
                    const i = registros.findIndex(r => r.referencia === referencia)
                    if( i > -1) registros.splice(i,1)
                }
                setRegistros(prev => [...[], ...registros])
            } else {
                setLoadingCarga(false)
                return
            }
        } 

        setMensajeLoading('Creando Productos')
        setLoadingMaster(true)
        setLoadingOperaciones(registros.length)
        setOperacionesListas(0)        
        let total_procesadas = 0
        // for( const producto of registros ){
        //         await crearProducto(producto)
        //         total_procesadas = total_procesadas + 1
        //         setOperacionesListas(total_procesadas)
        // }
      
        const dividir = sliceIntoChunks(registros, 10)
            for( const array of dividir ){
                await crearProductosMasivo(array)
                total_procesadas = total_procesadas + array.length
                setOperacionesListas(total_procesadas)
            }
        
        setLoadingMaster(false)
        setLoadingCarga(false)
        toast.success("Progreso finalizado")
        importacionExitosa()
        setTimeout(() => {
            // window.location = '/productos'
        }, 3500);
        return setRegistros([])
    }
    const handleCloseErrores = () => {
        return setShowModalErrores(false)
    }

    const mostrarErrores = () => {
        if(errores.length < 1) return false
        return <div className="mt-3">
            <Card bg="danger" className="p-2 text-white mb-3">
            <h6 className="mb-0">Hay {errores.length} errores detectados <Button size="sm" variant="link text-white" onClick={() => setShowModalErrores(true)}><BiSolidChevronRight size={20} /> <b>VER PROBLEMAS</b></Button></h6>
            </Card>
            <Modal show={showModalErrores} size="lg" onHide={handleCloseErrores} centered >
                <Modal.Header closeButton>
                <Modal.Title>Errores detectados</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                   {
                    errores.map((error,irr) => {
                        return <p className="mb-1" key={`error-${irr}`}><BiSolidChevronRight size={20} /> {error}</p>
                    })
                   }
                </Modal.Body>
            </Modal>
        </div>
    }

    const tablaRegistros = () => {
        if(registros.length < 1) return false

        return <div>
            <Table>
                <thead>
                    <tr>
                        <th>TÍTULO</th>
                        <th>CÓDIGO PROVEEDOR</th>
                        <th>CÓDIGO FÁBRICA</th>
                        <th>CÓDIGO IMPORTACIÓN</th>
                        {/* <th>PESO</th>
                        <th>ALTO</th>
                        <th>ANCHO</th>
                        <th>LARGO</th> */}
                        <th>PRECIO</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        registros.map((reg,ire) => {
                            return <tr key={`tabla-${ire}`}>
                            <th>{reg.titulo}</th>
                            <th>{reg.codigo_proveedor}</th>
                            <th>{reg.codigo_fabrica}</th>
                            <th>{reg.codigo_importacion}</th>
                            {/* <th>{reg.peso}</th>
                            <th>{reg.alto}</th>
                            <th>{reg.ancho}</th>
                            <th>{reg.largo}</th> */}
                            <th>{formatoMoneda(reg.precio)}</th>

                        </tr>
                        })
                    }
                </tbody>
            </Table>
       
        </div>
    }

    const cambiarRangoFecha = (item) => {
        return setSelectionRange(item.selection)
    }

    const validarCodigos = async (registros) => {
        let referencias = []
        registros.map(pedido => referencias.push(pedido.codigo))
        setLoadingMaster(true)
        setLoadingOperaciones(registros.length)
        setOperacionesListas(0)
        setErrores([])
        let nuevos_errores = []
        const arraydividido = sliceIntoChunks(referencias, 10)
        console.log({referencias, arraydividido})
        let cantidad_procesada = 0
        let registros_duplicados = []
        for( const datos of arraydividido){
            const response = await validarCodigosItems(datos)
            const duplicados = response.filter(da => da.valido === true)
            if(duplicados.length > 0) duplicados.map(ped => nuevos_errores.push(`Codigo Item ${ped.codigo} ya existe`))
            cantidad_procesada = cantidad_procesada + datos.length
            setOperacionesListas(cantidad_procesada)
            setErrores(prev => [...[], ...nuevos_errores])
            setDuplicados(prev => [...prev, ...duplicados])
            registros_duplicados = [...registros_duplicados, ...duplicados]
        }

        setLoadingMaster(false)
        setLoadingOperaciones(0)
        setOperacionesListas(0)
        return registros_duplicados
    }

    const handleChangeCliente = (e) => {
        console.log(e)
        if(!e) return setFiltroCliente('')
        return setFiltroCliente(e.rut)
    }

    const cancelarCarga = () => {
        setErrores([])
        return setRegistros([])
    }

    const accionesCarga = () => {
//         if(errores.length > 0) return false NO ESTOY SEGURO DE SI BORRAR
        if(registros.length < 1) return false
        let vehiculos = []
        registros.map(re => {
            const i = vehiculos.filter(v => v === re.vehiculo)
            if(i.length > 0) return 
            vehiculos.push(re.vehiculo)
            return
        })


        return <div className='mb-3'>
            {
                loadingCarga === true ? <div>
                    <Spinner animation='border' />
                    <h5>Espere un momento</h5>
                    </div> : <div>
                    
                    <Row>
                       <Col md={12}>
                        <h5>{registros.length} Registros cargados</h5>
                        {filtroCliente}
                        {/* <SelectorClientes allLabel="No asignar cliente" full_payload={true} titulo="Selecciona un cliente" onChange={handleChangeCliente} /> */}
                        <p className='mb-1'>Has click para crear <b className='text-primary'>{registros.length} productos</b></p>
                        <Button size="sm" variant="success" className="mt-3 mr-3" onClick={()=>subirRegistros()} >CREAR {registros.length} PRODUCTOS</Button>
                        <Button size="sm" variant="secondary" className="mt-3" onClick={()=>cancelarCarga()} >CARGAR NUEVOS DATOS</Button>
                        </Col>
                    </Row>
                    </div>
            }
        </div>
    }

    const mostrarRegistros = () => {
        if(loadingExcel === true ) return cargandoScreen()
        return <div>
            {mostrarErrores()}
            {accionesCarga()}
            {tablaRegistros()}
        </div>
    }

    const reemplazarLlave = (key) => {
        if(!formatoSeleccionado) return key
        const i = formatoSeleccionado.columnas.findIndex(e => e.target === key)
        if(i > -1) return formatoSeleccionado.columnas[i].key
        return key
    }

    const handleInputChange = (e) => {
        const target = e.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        let hojas = []
        setErrores(prev => [])
        if (name === 'file') {
            setLoadingExcel(true)
            let reader = new FileReader()
            reader.readAsArrayBuffer(target.files[0])
            reader.onloadend = (e) => {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, { type: 'array', cellDates:true, dateNF:'dd.mm.yyyy' });
    
            workbook.SheetNames.forEach(function(sheetName) {
              var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
              hojas.push(XL_row_object)
            })

            if(!hojas[0]) return toast.error('No se encontraron datos')
            if(Array.isArray(hojas[0]) !== true) return toast.error('No se encontraron datos')
            if(hojas[0].length < 1) return toast.error('No se encontraron datos')
            if(!hojas[0][0]) return toast.error('No se encontraron datos')
            
            let errores = []
            let registros = []
            
            if(errores.length > 0) return toast.error(errores.join(', ')) // SI HAY ERRORES DETENER

            let columnas_buscar = columnasrequeridas

            if(formatoSeleccionado){
                if(formatoSeleccionado.columnas){
                    if( Array.isArray(formatoSeleccionado.columnas) !== false ){
                        for( const col of formatoSeleccionado.columnas ){
                            const { target } = col
                            const pos = columnas_buscar.findIndex(c => c === target)
                            if(pos > -1) columnas_buscar[pos] = col.key
                        }
                    }
                }
            }

            console.log(hojas[0])
                        
            hojas[0].map((row,irow) => {
                
                let campos_faltantes = []
                columnas_buscar.map(nombre => {
                    // if(tipoAsignacion === "proveedor"){
                        if(typeof nombre !== "String" || nombre === null){
                        if(!row[nombre]) campos_faltantes.push(nombre)
                    } else {
                        if(!row[nombre]) campos_faltantes.push(nombre)
                    }
                })

                if(campos_faltantes.length > 0){
                    errores.push(`Fila ${irow+2} le faltan campos obligatorios ${campos_faltantes.join(', ')}`)
                    return false
                }

              
                let nuevo_registro = {
                    titulo: row[reemplazarLlave("titulo")] ? row[reemplazarLlave("titulo")].toString() : '',
                    codigo: row[reemplazarLlave("codigo")] ? row[reemplazarLlave("codigo")].toString() : '',
                    codigo_proveedor: row[reemplazarLlave("codigo_proveedor")] ? row[reemplazarLlave("codigo_proveedor")].toString() : '',
                    codigo_fabrica: row[reemplazarLlave("codigo_fabrica")] ? row[reemplazarLlave("codigo_fabrica")].toString() : '',
                    codigo_importacion: row[reemplazarLlave("codigo_importacion")] ? row[reemplazarLlave("codigo_importacion")].toString() : '',
                    peso: row[reemplazarLlave("peso")] ? row[reemplazarLlave("peso")] : 0,
                    alto: row[reemplazarLlave("alto")] ? row[reemplazarLlave("alto")] : 0,
                    ancho: row[reemplazarLlave("ancho")] ? row[reemplazarLlave("ancho")] : 0,
                    largo: row[reemplazarLlave("largo")] ? parseInt(row[reemplazarLlave("largo")]) : 0,
                    precio: row[reemplazarLlave("precio")] ? row[reemplazarLlave("precio")] : 0,
                }

                registros.push(nuevo_registro)
            })
            
            console.log(registros)
            if(errores.length > 0){
                setErrores(errores)
                setLoadingExcel(false)
                return toast.error(`Hay errores en el archivo, corrijelos e intenta de nuevo`)
            }
            setLoadingExcel(false)
            return setRegistros(registros)
          }
        }
    }

    const mostrarCargaExcel = () => {
        if(registros.length < 1){
            return <div>
                <Row>
           
           <Col md={12} className="mb-3">
           <Card className='p-3'>
           <h4 className='d-block'><AiOutlineFileExcel size={30} color='#2d9d57' /> Subir archivo excel</h4>
           <div className='p-4'>
           <Row className="justify-content-md-center">
               <Col md={6} className="text-center">
               <img className='d-block' src={imgExcel} style={{ width: 300, maxWidth: "80%", margin: "0 auto" }} />
               <Boton component="label" color="success" variant="contained" startIcon={<FaFileUpload />}>
               SUBIR EXCEL AQUÍ
               <input
                   type="file" 
                   hidden
                   name="file" 
                   id="file" 
                   className="form-control mb-2"
                   onChange={handleInputChange} 
                   placeholder="Archivo de excel" 
               />
               </Boton>
               </Col>
           </Row>
           </div>
           </Card>
           </Col>
       </Row>
            </div>
        }

        return false
    }


    return <div>
        <div className="fluid">
       <Header />
       <div className="container-fluid">
        <div className="row">
            <main className="col-md-12 ms-sm-auto col-lg-12 px-md-4 leftauto">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center border-top pb-2 mb-3">
              </div>
              <h4><i className="far fa-file-excel"></i> Importar datos excel</h4>
                <p>Puedes importar un archivo excel para crear productos</p>
                <a href={`${url_images}/models/productos-modelo.xlsx`}><Button variant="outline-primary" className="mb-3" size="sm"><AiOutlineFileExcel  />  DESCARGAR MODELO</Button></a>
            
                {mostrarCargaExcel()}
                
                
                <CargandoFlotante visualizar={loadingMaster} mensaje={mensajeLoading} procesando={loadingOperaciones} procesado={operacionesListas}  />
                {mostrarRegistros()}
            </main>
          </div>
        </div>
      </div>
        
    </div>

}

export default ProductosImportador