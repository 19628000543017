// import { authHeader } from '../_helpers';
import data, { key_local_storage_user } from '../lib/backend/data'
import { toast } from 'react-toastify';

export const userService = {
    login,
    logout
};

function login( username, password ) {
    return fetch(`${data.urlapi}/auth/login`, {
        method: 'POST',
        body: JSON.stringify({
            email: username.toLowerCase(),
            password: password
        }),
        headers: {
        'Content-Type':'application/json'
        }
    })
        .then(user => user.json())
        .then(user => {   
            if(!user){
                return false
            } else if(user.errorMessage){
                toast.error(user.errorMessage)
                return false
            } else if (user.tokenSession) {
                localStorage.setItem(key_local_storage_user, JSON.stringify(user));
                return user;
            }
            return false;
        })
        .catch(error => {
            console.log(error)
            return false
        })
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem(key_local_storage_user);
}
