const { configuracion_horas, configuracion_fechas } = require("./dates")

const control = {}

control.fechaATexto = (fecha, lang) => {
  const date = new Date(fecha)
  return `${date.toLocaleDateString('es-ES', configuracion_fechas )} · ${date.toLocaleTimeString('en-US', configuracion_horas)}`
}

control.validateEmail = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

control.ordenarProductos = (registros) => {
  const nuevos = registros.sort((a,b)=> {
    const posicion = typeof a.orden !== 'undefined' ? a.orden : 99999999
    return posicion > b.orden ? 1 : -1
  })
  return nuevos
}

control.showAmmount = (ammount) => {
  return Intl.NumberFormat("es-ES").format(ammount)
}

control.limpiarRut = (rut) => {
    if(!rut) return rut
    const dato = rut.toString().replace('.','')
    if(!dato) return rut
    const dato2 = dato.replace('.','')
    if(!dato2) return dato
    const dato3 = dato2.replace('.','')
    if(!dato3) return dato2
    return dato3
}

control.checkForDuplicatesEmails = (email,lista) => {
    const buscar = lista.filter(user => user.email === email)
    if(buscar.length > 0) return true
    return false
}

control.sliceIntoChunks = (arr, chunkSize) => {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
}

control.corregirTelefono = (num) => {

    if(!num){
      return false
    }

    const digito = num.toString()

    const longitud = digito.length

    if(longitud === 0){
      return false
    }
    const numero = digito.replace('+56', '56')
    if(longitud === 9){
      return `56${numero}`
    } else if(longitud === 8){
      return `569${numero}`
    }

    return numero
}

control.montoMoneda = (monto) => {
  return Intl.NumberFormat("es-ES").format(monto)
}

const dv = function(T) {
  var M = 0,
      S = 1;
  for (; T; T = Math.floor(T / 10))
      S = (S + T % 10 * (9 - M++ % 6)) % 11;
  return S ? S - 1 : 'k';
}

control.validarRut = function(rutCompleto) {
  if (!/^[0-9]+-[0-9kK]{1}$/.test(rutCompleto))
      return false;
  var tmp = rutCompleto.split('-');
  var digv = tmp[1];
  var rut = tmp[0];
  if (digv == 'K') digv = 'k';
  return (dv(rut) == digv);
}

control.formatoRut = (value) => {
  return value
  .replace(/[^0-9\dkK]/g, '')
    .replace( /^(\d{1,2})(\d{3})(\d{3})(\w{1})$/, '$1.$2.$3-$4')
}

control.formatoMoneda = value => {
  return Intl.NumberFormat("en-DE").format(Math.round(value))
}

control.formatearRUTChileno = (rut) => {
  /// Eliminar puntos y guión del RUT (dejar solo dígitos y K)
 const rutLimpio = rut.replace(/[.-]/g, "").toUpperCase();

 if (!/^[0-9]+[0-9K]?$/.test(rutLimpio)) {
   // El RUT contiene caracteres inválidos
   return rut;
 }

 const rutSinDigitoVerificador = rutLimpio.slice(0, -1);
 const digitoVerificador = rutLimpio.slice(-1);

 let rutFormateado = "";
 let contador = 0;

 // Agregar los puntos al RUT, evitando número negativo en el primer dígito
 for (let i = rutSinDigitoVerificador.length - 1; i >= 0; i--) {
   rutFormateado = rutSinDigitoVerificador.charAt(i) + rutFormateado;
   contador++;
   if (contador % 3 === 0 && i !== 0) {
     rutFormateado = `.${rutFormateado}`;
   }
 }

 // Agregar el guión y el dígito verificador si el RUT tiene dos caracteres o más
 if (rutFormateado.length > 1) {
   rutFormateado += "-";
 }
 rutFormateado += digitoVerificador;

 return rutFormateado;
 };

 control.limpiarStringNumeros = (cadena) => {
  const regex = /\D/g
  return cadena.replace(regex, '');
};

module.exports = control