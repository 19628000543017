import React, { useEffect, useRef, useState } from 'react'
import { Row, Col, Button, Table, Card, Spinner, Breadcrumb, Alert, Modal, Tabs, Tab } from 'react-bootstrap'
import data, { key_local_storage_cart, key_local_storage_pedidos, key_local_storage_user, urlapi } from '../../lib/backend/data'
import { toast } from 'react-toastify';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { addMoreDays } from '../../lib/helpers/dates';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'
import { Link } from 'react-router-dom';
import Inventario from '../../subComponents/repuestos/inventario';
import SinDatos from '../../subComponents/general/sin_registros';
import { fechaATexto, formatoMoneda, validateEmail } from '../../lib/helpers/helpers';
import { logotipo, logotipo_2 } from '../../lib/global/data';
import { useReactToPrint } from 'react-to-print';
import { simbolByType } from '../../lib/helpers/main';
import Barcode from 'react-barcode';
import QRCode from 'react-qr-code';

const ImpresionCotizacion = React.forwardRef((props, ref) => {
    const {
        cotizacion,
        user
    } = props

    const ruta = cotizacion

    let numero = ''
    if(cotizacion.creador){
        if(typeof cotizacion.creador === "object"){
            if(cotizacion.creador.phone) numero = cotizacion.creador.phone
        }
    }

    const chekcIcon = producto => {
        if(producto.cantidad_pickeada === producto.cantidad){
            return <i className="mr-2 text-success fas fa-check-circle"></i>
        } else if (producto.cantidad_pickeada > producto.cantidad ){
            return <i className="mr-2 text-primary fa-solid fa-badge-check"></i>
        }
    }

    let calcular_total = ruta.productos.reduce((prev, next) => {
        let cantidad = parseInt(next.cantidad)
        if(isNaN(cantidad)) cantidad = 0
        return prev + (next.precio * cantidad)
    }, 0)


    return <div ref={ref} id="imprimir-v2" style={{ wordWrap: "break-word", width: "100%" }}>
                <img alt="logo" id="logo" className='mb-3' style={{ width: "55%", maxWidth: 200, marginBottom: 10, margin: "0 auto", display: "block" }} src={logotipo_2}  />
                <table style={{ margin: 0 }}>
                    <tr>
                        <td><h5 style={{ textAlign:"center" }}>SOCIEDAD COMERCIALIZADORA DE PRODUCTOS Y SERVICIOS MESINA SPA</h5><hr style={{ border: "1px dashed black" }} /></td>
                        </tr>
                        <tr>
                        <td><h5>76.464.459-K</h5></td>
                        </tr>
                        <tr>
                        <td><h5>(+562) 2222 0608</h5></td>
                        </tr>
                        <tr>
                        <td><h5>10 de Julio Nº 560, Santiago Región, Metropolitana, Chile</h5></td>
                        </tr>
                        <tr>
                        <td><h5>Fecha: {fechaATexto(ruta.createdAt)}</h5></td>
                        </tr>
                        <tr>
                        <td><h4>Cotización {ruta.id}</h4></td>
                        </tr>
                </table>
                <hr style={{ border: "1px solid black" }} />
                <table>
                    <tr><td>CLIENTE</td></tr>
                    { ruta.detalles_cliente ? typeof ruta.detalles_cliente === "object" ? <tr><td>{ruta.detalles_cliente.vatId}</td></tr> : false  : false }
                    { ruta.detalles_cliente ? typeof ruta.detalles_cliente === "object" ? <tr><td>{ruta.detalles_cliente.name ? ruta.detalles_cliente.name.toUpperCase() : "SIN INFORMACIÓN"}</td></tr> : false : false}
                </table>
                <hr style={{ border: "1px solid black" }} />
                    <table>
                        <tr><td>{ruta.productos.length} Productos</td></tr>
                {
                    ruta.productos.map((producto,i) => {
                        return <tr  key={`${producto.id}`} style={{ textTransform: "uppercase" }}>
                            <td xs={9}>{chekcIcon(producto)}{producto.titulo} <b>X {producto.cantidad}</b></td>
                            <td xs={3}>{formatoMoneda(producto.precio)}</td>
                        </tr>
                    })
                }
                </table>
                <hr style={{ border: "1px dashed black" }} />
                <table>
                    <tr>
                        <td>SUBTOTAL</td>
                        <td>{formatoMoneda(calcular_total)}</td>
                    </tr>
                    <tr>
                        <td>DESCUENTO</td>
                        <td>0</td>
                    </tr>
                    <tr>
                        <td>TOTAL</td>
                        <td>{formatoMoneda(calcular_total)}</td>
                    </tr>
                </table>
                <hr style={{ border: "1px dashed black" }} />
                <div style={{ display: "block", textAlign: "center", margin: "0 auto" }}>
                    { numero ? <QRCode textAlign="center" size={100} value={`https://wa.me/${numero}?text=Hola necesito ayuda sobre mi cotización ${cotizacion.id}`} /> : false}
                </div>
                <div className='barcode'><Barcode textAlign="center" height={50} value={cotizacion._id} /></div>

                <h5 style={{ fontWeight: 300, marginBottom: 0, marginTop: 0 }} >Los Precios Incluyen IVA · Esta cotización tiene una fecha de validez de 3 días a partir de su emisión</h5>
                <h6 className='text-center' style={{ paddingBottom: 25, marginTop: 0 }} >WWW.MESINA.CL</h6>
                <h1 style={{ color: "white" }}>--</h1>
                <div style={{ pageBreakAfter: "always" }}></div>
  </div>
})

const CotizacionEditar = (props) => {
    const initialDate = {
        startDate: new Date(),
        endDate: addMoreDays(new Date(),1),
        key: 'selection',
    }
    const user = JSON.parse(localStorage.getItem(key_local_storage_user))
    const [ selectionRange, setSelectionRange ] = useState(initialDate)
    const [ ruta, setRuta ] = useState({})
    const [ aplicaciones, setAplicaciones ] = useState([])
    const [ conductores, setConductores ] = useState([])
    const [ loadingConductores, setLoadingConductores ] = useState(true)
    const [ loadingRuta, setLoadingRuta ] = useState(true)
    const [ conductor, setConductor ] = useState(false)
    const [ loadingGuia, setLoadingGuia ] = useState(false)
    const [ loadingEscaneo, setLoadingEscaneo ] = useState(false)
    const [ loadingCreacion, setLoadingCreacion ] = useState(false)
    const [ modelosMultiples, setModelosMultiple ] = useState([])
    const [ descripcion, setDescripcion ] = useState('')
    const [ email, setEmail ] = useState('')
    const [ showButtonUpdateDate, setShowButtonUpdateDate ] = useState(false)
    const [ loadingAplicacionId, setAplicacionId ] = useState(false)
    const [ loadingCopiado, setLoadingCopiado ] = useState(false)
    const [ guardandoCambios, setGuardandoCambios ] = useState(false)
    const [ loadingAcciones, setLoadingAcciones ] = useState(false)
    const [ enviandoEmail, setEnviandoEmail ] = useState(false)
    const [ nuevoCodigoImportar, setNuevoCodigoImportar ] = useState([])
    const [ previewCarrito, setPreviewCarrito ] = useState([])
    const [ validando, setValidando ] = useState(false)
    const [ codigo, setCodigo ] = useState("")
    const [ facturando, setFacturando ] = useState("")
    const token = props.token ? props.token : false
    const idproducto = props.idproducto ? props.idproducto : false
    const inputEscaneo = useRef(false)
    const [ openModal, setOpenModal ] = useState(false)
    const componentRef = useRef()
    const pageStyle = `
    @media print {
        @page {
            size: 80mm 297mm;
          }
        #imprimir-v2 {
            padding:20px;
            word-wrap: break-word;
            width: 100% !important;
        }
        table {
            width: 100%;
        }
        h6, h2, h3, h4, h1, .col, .row, p, table {
            font-size: 23px !important;
        }
    }  
    `;

    useEffect(() => {
        obtenerProducto(idproducto)
    },[])

    
    const buscarPDFGuia = async (salesWaybillId, tok) => {
        setFacturando(true)
        return fetch(`https://api.laudus.cl/sales/waybills/${salesWaybillId}/pdf`,{
          method:'GET',
          headers: {
              'Content-Type':'application/json',
              'Authorization': `Bearer: ${tok}`
          }
      })
      .then(res => {
          if(res.status === 401) {
            setFacturando(false)
            return toast.error("NO autorizado para consultar PDF")
          }
          return res.blob()
      })
      .then(blob => {
              var url = window.URL.createObjectURL(blob);
              var a = document.createElement('a');
              a.href = url;
              a.download = `${salesWaybillId}.pdf`;
              document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
              a.click();    
              a.remove();
          return setFacturando(false)
      })
      .catch(error => {
          toast.error("Error al consultar la información, intente nuevamente")
          return setFacturando(false)
      })
      }

    const emitirGuia = async (id) => {
        setLoadingGuia(true)
        return fetch(`${data.urlapi}/pedidos/guia?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingGuia(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingGuia(false)
            } else if(typeof res.docNumber !== "undefined"){
                toast.success(`Emitida exitosamente la guia ${res.docNumber}`)
                return buscarPDFGuia(res.docNumber, res.token)
            }
            return setLoadingGuia(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingConductores(false)
        })
    }

    const reiniciarPedido = async (id) => {
        setLoadingRuta(true)
        return fetch(`${data.urlapi}/pedidos/reset?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingRuta(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingRuta(false)
            } else if(Array.isArray(res) !== false){
                if(res.length > 0) return obtenerProducto(idproducto)
            }
            return setLoadingRuta(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingConductores(false)
        })
    }
    const obtenerProducto = async (id) => {
        setLoadingRuta(true)
        return fetch(`${data.urlapi}/cotizaciones?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingRuta(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingRuta(false)
            } else if(res._id){
                setRuta(res)
                setOpenModal(true)
                const instancia_productos = JSON.parse( JSON.stringify(res.productos) )
                setPreviewCarrito([...[], ...instancia_productos])
            }
            return setLoadingRuta(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingConductores(false)
        })
    }

    const actualizarPedidoProducto = async (payload, qty) => {
        let nuevo_payload = JSON.parse(JSON.stringify(payload))
        nuevo_payload.cantidad_pickeada = qty
        return fetch(`${data.urlapi}/pedidos/producto`,{
            method:'POST',
            body: JSON.stringify(nuevo_payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return false
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return false
            } else if(res._id){
                return res
            }
            return false
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return false
        })
    }
    
    const agregarProducto = async (payload) => {
        payload.id_pedido = idproducto
        payload.cantidad_pickeada = payload.cantidad
        payload.statue = "active"
        delete payload.createdAt
        delete payload.updatedAt
        setLoadingAcciones(true)
        return fetch(`${data.urlapi}/pedidos/nuevo-producto`,{
            method:'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingAcciones(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingAcciones(false)
            } else if(res._id){
                ruta.productos.push(res)
                setNuevoCodigoImportar([])
                setRuta(prev => ({...{}, ...ruta}))
                toast.success("Agregado exitosamente")
            }
            return setLoadingAcciones(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingAcciones(false)
        })
    }

    const obtenerProveedores = async () => {
        setLoadingConductores(true)
        return fetch(`${data.urlapi}/conductores/list-full`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingConductores(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingConductores(false)
            } else if(Array.isArray(res) !== false){
                setConductores(res.map(c => ({ value: c._id, label: `${c.patente.toUpperCase()} · ${c.modelo.toUpperCase()}` })))
            }
            return setLoadingConductores(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingConductores(false)
        })
    }

    const actualizarProducto = async () => {
        setLoadingCreacion(true)
        return fetch(`${data.urlapi}/pedidos`,{
            method:'PUT',
            body: JSON.stringify(ruta),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingCreacion(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingCreacion(false)
            } else if(res._id){
                toast.success('Guardada exitosamente')
                return setTimeout(() => {
                    window.location = `/product-edit-${idproducto}`
                }, 1000);
            }
            return setLoadingCreacion(false)
        })
        .catch(error => {
            toast.error(error.message)
            return setLoadingCreacion(false)
        })
    }

    const handleChangeProducto = (e) => {
        const { name, value } = e.target
        ruta[name] = value
        return setRuta(ruta)
    }

    const cambiarRangoFecha = (item) => {
        setShowButtonUpdateDate(true)
        return setSelectionRange(item.selection)
    }

    const handleChangeSelectConductor = (e) => {
        return setConductor(e.value)
    }

    const confirmarCambioFecha = async () => {
        setLoadingCreacion(true)
        return fetch(`${data.urlapi}/rutas/fecha`,{
            method:'PUT',
            body: JSON.stringify({
                id: ruta._id,
                fecha_min_entrega: selectionRange.startDate,
                fecha_max_entrega: selectionRange.endDate,
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingCreacion(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingCreacion(false)
            } else if(res._id){
                toast.success('Actualizado exitosamente')
            }
            return setLoadingCreacion(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingCreacion(false)
        })
    }

    const solicitarCambioFecha = (id) => {
        return confirmAlert({
            title: `¿Cambio de fechas?`,
            message: `Actualizar el rango de entrega modificará individualmente cada orden siempre y cuando éstas no estén finalizadas.`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => confirmarCambioFecha()
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              }
            ]
          })
    }

    const mostrarBotonActualizarFecha = () => {
        if(!showButtonUpdateDate) return false
        return <Button size="sm" className='w-100' onClick={()=>solicitarCambioFecha()} >ACTUALIZAR RANGO DE ENTREGA</Button>
    }

    const confirmarEliminadoRuta = () => {
        setLoadingRuta(true)
        return fetch(`${data.urlapi}/cotizaciones?id=${ruta._id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingRuta(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingRuta(false)
            } else if(res._id){
                toast.success('Eliminada exitosamente')
                return setTimeout(() => {
                    window.location = '/cotizaciones'
                }, 1000);
            }
            return setLoadingRuta(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingRuta(false)
        })
    }

    const guardarCambios = (id, posicion) => {
        let actualizar = false
        let crear = 0
        if(id){
            const i = aplicaciones.findIndex(a => a._id === id)
            if(i < 0) return false
            actualizar = aplicaciones[i]
        } else {
            crear++
            actualizar = aplicaciones[posicion]
        }
        if(!actualizar.id_cilindrada) return toast.error("Cilindrada es requerido")
        if(!actualizar.id_modelo) return toast.error("Modelo es requerido")
        setAplicacionId(id)
        return fetch(`${data.urlapi}/aplicaciones`, {
            method:'PUT',
            body: JSON.stringify(actualizar),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                toast.success("Guardado exitosamente")
                if(crear > 0){
                    aplicaciones[posicion]._id = res._id
                    setAplicaciones(prev => [...[], ...aplicaciones])
                }
            }
            return setAplicacionId(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            setAplicacionId(false)
        })
    }

    const solicitarEliminarRuta = () => {
        return confirmAlert({
            title: `¿Estás seguro?`,
            message: `Estás a punto de eliminar esta ruta y todas las órdenes asociadas`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => confirmarEliminadoRuta()
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              }
            ]
          })
    }

    const handleSelectModelo = (data, id) => {
        console.log({ data })
        const i = aplicaciones.findIndex(a => a._id === id)
        if(i < 0) return false
        aplicaciones[i].id_modelo = data._id
        return setAplicaciones(prev => [...[], ...aplicaciones])
    }

    const handleSelectCilindrada = (data, id) => {
        const i = aplicaciones.findIndex(a => a._id === id)
        if(i < 0) return false
        aplicaciones[i].id_cilindrada = data._id
        return setAplicaciones(prev => [...[], ...aplicaciones])
    }

    const handleChangeAplicacion = (e) => {
        const { name, value } = e.target
        const idapp = e.target.getAttribute("idapp")
        const pos = e.target.getAttribute("pos")
        if(idapp){
            const i = aplicaciones.findIndex(a => a._id === idapp)
            aplicaciones[i][name] = value
        } else {
            aplicaciones[pos][name] = value
        }
        return setAplicaciones(prev => [...[], ...aplicaciones])
    }

    const solicitarEliminarApp = (id, pos) => {
        if(!id) return eliminarApp(id,pos)
        return confirmAlert({
            title: 'Confirmar',
            message: `Estás a punto de eliminar esta aplicación, esta acción no se puede deshacer`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => eliminarApp(id,pos)
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              },
            ],
          })
    }

    const eliminarApp = async (id,pos) => {
        if(!id) {
            aplicaciones.splice(pos,1)
            return setAplicaciones(prev => [...[], ...aplicaciones])
        }
        setAplicacionId(id)
        return fetch(`${data.urlapi}/pedidos/aplicaciones?id=${id}`, {
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                toast.success("Guardado exitosamente")
                const i = aplicaciones.findIndex(a => a._id === id)
                if(i > -1){
                    aplicaciones.splice(i,1)
                    setAplicaciones(prev => [...[], ...aplicaciones])
                }
            }
            return setAplicacionId(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            setAplicacionId(false)
        })
    }

    const fetchCodigo = (codigo_busqueda) => {
        if(!codigo_busqueda) return false
        return fetch(`${data.urlapi}/productos/search-code`,{
            method: "POST",
            body: JSON.stringify({
                codigo_busqueda: codigo_busqueda.toUpperCase()
            }),
            headers: {
                'Content-Type': 'application/json'
            }
            })
            .then(res => res.json())
            .then(resultados => {
                if(!resultados){
                    return []
                } else if(resultados.errorMessage){
                    toast.error(resultados.errorMessage)
                    return []
                } else if(Array.isArray(resultados) !== false){
                    return resultados
                }
                return []
            })
            .catch(error => {
                return []
            })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoadingEscaneo(true)
        const pos = ruta.productos.findIndex(p => {
            if(!p.codigo) return false
            return p.codigo.toString().toLowerCase() === codigo.toString().toLowerCase()
        })
        if(pos < 0){
            const encontrar = await fetchCodigo(codigo)
            setLoadingEscaneo(false)
            if(!encontrar || Array.isArray(encontrar) !== true) {
                if(encontrar.length < 1) return toast.error("Código no encontrado")
                return toast.error("Código no encontrado")
            }
            if(encontrar.length < 1){
                toast.error("Sin resultados de la búsqueda")
            }
            setNuevoCodigoImportar(encontrar.map(v => {
                v.cantidad = 1
                return v
            }))
            return false
        }
        const nueva_cantidad = ruta.productos[pos].cantidad_pickeada + 1
        if(nueva_cantidad > ruta.productos[pos].cantidad){
            setLoadingEscaneo(false)
            return toast.error("Cantidad requerida excedida")
        }
        const actualizarBD = await actualizarPedidoProducto(ruta.productos[pos], nueva_cantidad)
        if(!actualizarBD){
            setLoadingEscaneo(false)
            return toast.error("No se pudo actualizar, intenta de nuevo")
        }
        ruta.productos[pos].cantidad_pickeada = nueva_cantidad
        setLoadingEscaneo(false)
        toast.success(`Pickeado exitosmente ${ruta.productos[pos].titulo}`)
        return setRuta(prev => ({...{}, ...ruta}))
    }

    const mostrarMensajeEstado = () => {
        const incompletos = ruta.productos.filter(p => p.cantidad !== p.cantidad_pickeada)
        const listos = ruta.productos.filter(p => p.cantidad === p.cantidad_pickeada)
        const aumentados = ruta.productos.filter(p => p.cantidad_pickeada > p.cantidad)
        if(incompletos.length < 1) return <Alert variant="success"><i className="fas fa-check-circle"></i> TODO LISTO</Alert>
        return <Card className='p-3 mb-3'>
            <Row>
            <Col xs={4}>
                <h6 className='mb-0' style={{ fontSize: 12 }}>Productos pendientes</h6>
                <h2 className='mb-0'>{incompletos.length}</h2>
            </Col>
            <Col xs={4}>
                <h6 className='mb-0' style={{ fontSize: 12 }}>Productos pickeados</h6>
                <h2 className='mb-0'>{listos.length}</h2>
            </Col>
            <Col xs={4}>
                <h6 className='mb-0' style={{ fontSize: 12 }}>Productos excedidos</h6>
                <h2 className='mb-0'>{aumentados.length}</h2>
            </Col>
            </Row>
        </Card>
    }

    const handleChangeCodigo = (e) => {
        const { value } = e.target
        return setCodigo(value)
    }

    const chekcIcon = producto => {
        if(producto.cantidad_pickeada === producto.cantidad){
            return <i className="mr-2 text-success fas fa-check-circle"></i>
        } else if (producto.cantidad_pickeada > producto.cantidad ){
            return <i className="mr-2 text-primary fa-solid fa-badge-check"></i>
        }
    }

    const mostrarAcciones = () => {
        if(loadingAcciones) return <div>
            <Spinner animation='border' />
            <h5>Espere un momento</h5>
        </div>
        if(nuevoCodigoImportar.length > 0){
            return <div>
                <Card className='p-2 mb-3 text-light' bg="primary">
                    <Button variant="link" className='text-light text-left' size="sm" onClick={() => setNuevoCodigoImportar([]) }>CANCELAR</Button>
                    <h3 className='mb-0'>Agregaste un código que no está en este pedido</h3>
                    <hr className='mb-2' />
                    {
                        nuevoCodigoImportar.map((code,pos) => {
                            return <div key={code._id}>
                                <h5>{code.titulo}</h5>
                                <h4>{code.codigo}</h4>
                                <input defaultValue={code.cantidad} className='form-control mb-3' placeholder='Introduce la cantidad' pos={pos} onChange={handleChangeCantidad} type="number" />
                                <Button size="sm" variant='light' onClick={() => agregarProducto(code)}>AGREGAR ESTE CÓDIGO</Button>
                                {nuevoCodigoImportar.length === (pos+1) ? false : <hr className='text-white mb-2' /> }
                            </div>
                        })
                    }
                </Card>
            </div>
        }
    }

    const guardarCambiosPedido = async (datos) => {
        const nuevo_payload = {
            id_cotizacion: ruta._id,
            productos: datos ? datos : previewCarrito
        }

        setGuardandoCambios(true)
        return fetch(`${data.urlapi}/cotizaciones/meta`,{
            method:'PUT',
            body: JSON.stringify(nuevo_payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                toast.success("Guardado exitosamente, espere mientras se recarga la página")
                setTimeout(() => {
                    window.location.reload()
                }, 1000);
            }
            return setGuardandoCambios(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setGuardandoCambios(false)
        })
    }

    const agregarACarrito = () => {

        const productos = JSON.parse(JSON.stringify(ruta.productos))

        const preview_carrito = productos.map(pro => {
            pro.cantidad = pro.cantidad_pickeada
            pro.pedido = ruta.id
            delete pro.cantidad_pickeada
            return pro.cantidad > 0 ? pro : false
        }).filter(pro => pro)

        setOpenModal(true)

        return setPreviewCarrito(preview_carrito)
    }

    const handleClose = () => {
        setOpenModal(false)
        setPreviewCarrito([])
    }

    const confirmarEnvioCarrito = () => {
        let carrito_guardado = []
        if(localStorage.getItem(key_local_storage_cart)){
            const parseCart = JSON.parse(localStorage.getItem(key_local_storage_cart))
            if(parseCart.length > 0){
                const buscar_agregados_mismo_pedido = parseCart.filter(pro => pro.cotizacion === ruta.id).length
                if(buscar_agregados_mismo_pedido > 0) return toast.error("Ya agregaste productos de esta cotización al carrito, para volver a utilizar esta opción debes eliminar los productos de esta cotización del carrito desde la página de facturación") 
            }
            carrito_guardado = parseCart
        }
        
        const productos = JSON.parse(JSON.stringify(previewCarrito))

        const preview_carrito = productos.map(pro => {
            pro.cotizacion = ruta.id
            delete pro.descuentos
            return pro.cantidad > 0 ? pro : false
        }).filter(pro => pro)

        carrito_guardado = [...carrito_guardado, ...preview_carrito]
        localStorage.setItem(key_local_storage_cart, JSON.stringify(carrito_guardado))
        return toast.success("Operación realizada exitosamente")
    }

    const envioCarrito = () => {
        return confirmAlert({
            title: `Confirma el envío al carrito`,
            message: `Los productos se agregarán al carrito, y el número de pedido será enlazado también al carrito de manera que cuando emitas un DTE, este pedido quedará bloqueado y no podrás volver a trabajar sobre él`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => confirmarEnvioCarrito()
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              }
            ]
          })
    }

    const removerDeCarritoPreview = (i) => {
        previewCarrito.splice(i,1)
        return setPreviewCarrito([...[], ...previewCarrito])
    }

    const handleChangeCantidad = (e, i) => {
        const { value } = e.target
        const input = value ? value.replace(/[.,;]/g, "") : ''
        let numero = parseInt(input)
        if(isNaN(numero)) numero = 0
        previewCarrito[i].cantidad = numero
        return setPreviewCarrito([...[], ...previewCarrito])
    }

    const handleChangePrecio = (e, i) => {
        const { name, value } = e.target
        const input = value ? value.replace(/[.,;]/g, "") : ''
        let numero = parseInt(input)
        if(isNaN(numero)) numero = 0
        console.log({value,numero})
        previewCarrito[i].precio = numero

        const valor_string = formatoMoneda(numero).toString()
        document.getElementById(name).value = valor_string

        return setPreviewCarrito([...[], ...previewCarrito])
    }

    const mostrarDescuentosAplicados = (producto) => {
        if(!producto.descuentos) return false
        if(Array.isArray(producto.descuentos) !== true) return false
        if(producto.descuentos.length < 1) return false
        return producto.descuentos.map((descuento,i) => <Card key={`${descuento._id}-${i}`} bg="secondary" className='p-1 text-center'><h6 style={{ fontSize: 11, color: "white" }} className='mb-0'>DESCUENTO {descuento.concepto}: {simbolByType(descuento.tipo, formatoMoneda(descuento.valor))}</h6></Card>)
    }

    const calcularPorcentajeDescuento = (precioOriginal, precioModificado) => {
        if(!precioOriginal) return false
            const descuento = precioOriginal - precioModificado;
            const porcentajeDescuento = (descuento / precioOriginal) * 100;
            return <Card className='p-2 mt-1'>
                <h6 className='mb-0'>PRECIO ORIGINAL <b className='text-primary'>${formatoMoneda(precioOriginal)}</b></h6>
                <h6 className='mb-0'>DESCUENTO <b className='text-primary'>{porcentajeDescuento.toFixed(0)}%</b></h6>
            </Card>
    }

    const mostrarModalCarritoPreview = () => {
        if(!previewCarrito) return false
        if(previewCarrito.length < 1) return false

        let calcular_total = previewCarrito.reduce((prev, next) => {
            let cantidad = parseInt(next.cantidad)
            if(isNaN(cantidad)) cantidad = 0
            return prev + (next.precio * cantidad)
        }, 0)

        return <Card className='p-3 mb-3'>
            <h4>Modificar</h4>
            <Table>
                <thead>
                    <tr>
                        <th>Producto</th>
                        <th>Cantidad</th>
                        <th>Precio</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        previewCarrito.map((pro,i) => {

                            const cantidad = isNaN(parseInt(pro.cantidad)) ? 0 : parseInt(pro.cantidad)
                            const total = cantidad * pro.precio
                            let numero = parseInt(pro.precio, 10)
                            if(isNaN(numero)) numero = 0
                            return <tr key={`pro-${i}`}>
                                <th>
                                    <h5 className='mb-0'>{pro.titulo}</h5>
                                    <p className='mb-0'>{pro.codigo}</p>
                                <Button size="sm" style={{ fontSize: 12 }} variant="outline-danger" onClick={() => removerDeCarritoPreview(i)}><i className="fas fa-trash"></i> REMOVER</Button>
                                </th>
                                <th>
                                    <input className='form-control' type="number" min="1" name={`cantidad`} defaultValue={pro.cantidad} onChange={(e) => handleChangeCantidad(e, i)}  />
                                </th>
                                <th>
                                    <input className='form-control' id={`pro-${i}`} name={`pro-${i}`} defaultValue={formatoMoneda(numero)} onChange={(e) => handleChangePrecio(e, i)}  />
                                    {calcularPorcentajeDescuento(pro.precio_original, numero)}
                                    <div className='mb-1'></div>
                                    {mostrarDescuentosAplicados(pro)}
                                </th>
                                <th>
                                    <h4 className='mb-0'>${formatoMoneda(total)}</h4>
                                </th>
                            </tr>
                        })
                    }
                    <tr>
                        <th></th>
                        <th><h4>TOTAL</h4></th>
                        <th><h4>${formatoMoneda(calcular_total)}</h4></th>
                    </tr>
                </tbody>
            </Table>
            <Row>
                <Col>
                <Button style={{ width: "100%" }} variant='secondary' onClick={() => {
                setOpenModal(false)
                setPreviewCarrito([])
                setTimeout(() => {
                    guardarCambiosPedido([])
                }, 100);
                }}>CANCELAR</Button>
                </Col>
                <Col>
                { guardandoCambios === true ? <Spinner animation='border' /> : <Button variant="outline-success" style={{ width: "100%" }} className='mr-2' onClick={()=>guardarCambiosPedido()}><i className="fas fa-save"></i> GUARDAR CAMBIOS</Button> }
                </Col>
            </Row>
            
        </Card>
    }

    const handleChangeEmail = (e) => {
        const { value } = e.target
        setEmail(value)
    }

    const enviarEmail = () => {
        if(!email) return toast.error("Escribe un email primero")
        if(!validateEmail(email)) return toast.error("Email inválido")
        setEnviandoEmail(true)
        return fetch(`${data.urlapi}/cotizaciones/email`,{
            method:'POST',
            body: JSON.stringify({
                email,
                id: ruta._id
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res.success === true){
                toast.success("Enviado exitosamente")
            }
            return setEnviandoEmail(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setEnviandoEmail(false)
        })
    }

    const imprimirPedidoActual = (div) => {
        var mywindow = window.open('', 'PRINT', 'height=400,width=600');
        mywindow.document.write('<html><head><title>' + document.title  + '</title><link href="https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap" rel="stylesheet"><meta name="viewport" content="width=device-width, initial-scale=1.0">');
        mywindow.document.write(`<style>#imprimir-v2 {
            padding:20px;
            word-wrap: break-word;
            width: 100% !important;
        }
        #logo {
            margin: 0 auto !important;
            display: block !important;
            text-align: center !important;
        }
        td {
            vertical-align: top;
        }
        .barcode svg {
            width: 100%;
        }
        table {
            width: 100%;
        }
        a,span,h1,h2,h3,h4,h5,h6,p,ul,form,input,table,button,label,select{
            font-family: 'Rubik', sans-serif !important;
            margin-bottom: 0px;
          }
          h5 {
            font-size: 11px !important;
            margin-bottom:0px !important;
          }
            h6, h2, h3, h4, h5, h1, .col, .row, p, table {
            font-size: 12px !important;
            line-height:10px;
            margin-bottom:0px;
            font-weight:400;
        }</style></head><body >`);
        mywindow.document.write(document.getElementById(div).innerHTML);
        mywindow.document.write('</body></html>');
        //mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/
        setTimeout(() => {
            mywindow.print();
        }, 500);
        setTimeout(() => {
            mywindow.close()
        }, 1000);
      }

    if(loadingRuta === true) return <Spinner animation='border' />
    if(Object.keys(ruta).length < 1) return <Row className="justify-content-md-center">
    <Col md={6} className="text-center">
      <img src="images/pedidos.png" style={{ width: "50%" }} />
    <h4>No hay datos suficientes para visualizar esta sección</h4>
    </Col>
    </Row>
    const i = conductores.findIndex(c => c.value === ruta.id_conductor)
    let default_vehiculo = false
    if(i > -1) default_vehiculo = conductores[i]

    return <div>
        <Breadcrumb>
        <Breadcrumb.Item href="#"><Link to="/cotizaciones">Cotizaciones</Link> </Breadcrumb.Item>
        <Breadcrumb.Item active>Editar</Breadcrumb.Item>
        </Breadcrumb>
        <hr />
    <Row>
        <Col md={12}>
            <Row>
            <Col md={6}>
                <h4>Cotización {ruta.id}</h4>
            </Col>
            <Col md={6} className="text-right"><Button size="sm" variant="link" className='p-0 text-danger' onClick={()=>solicitarEliminarRuta()}>ELIMINAR</Button></Col>
            </Row>
        </Col>
        <Col md={12} className="mb-3">
            { /* loadingCreacion === true ? <Spinner animation='border' /> : <Button size="sm" variant="outline-primary" className='mr-2' onClick={()=>reiniciarPedido(idproducto)}>REINICIAR A COTIZACIÓN ORIGINAL</Button> */ }
            { /* <Button size="sm" variant="outline-primary" className='mr-2' onClick={()=>imprimir()}><i className="fas fa-print"></i> IMPRIMIR</Button> */}
        </Col>
        <Col md={12}>

        <Tabs defaultActiveKey="email" id="uncontrolled-tab-example" className="mb-3">
            <Tab eventKey="email" title="Enviar por email">
                <Card className='p-3 mt-3 mb-3'>
                <Row>
                    <Col md={12}>
                        <h4>Enviar cotización por email</h4>
                    </Col>
                    <Col md={2}><input className='form-control' placeholder='EMAIL' onChange={handleChangeEmail} /></Col>
                    <Col md={2}>
                        { enviandoEmail ? <Spinner animation='border' /> : <Button onClick={() => enviarEmail()}><i className="fas fa-paper-plane"></i> ENVIAR EMAIL</Button>}
                    </Col>
                    <Col md={3}>
                    { validando ? <Spinner animation='border' /> : <Button style={{ width: "100%" }} variant='success' onClick={() => envioCarrito()}>CONFIRMAR Y ENVIAR AL CARRITO</Button> }
                    </Col>
                </Row>
                </Card>
            </Tab>
            <Tab eventKey="modificar" title="Modificar cotización">
                {mostrarModalCarritoPreview()}
            </Tab>
        </Tabs>

        </Col>
    </Row>
    <Button className='mb-3'  onClick={() => imprimirPedidoActual('imprimir-v2')}>IMPRIMIR</Button>
    <ImpresionCotizacion user={user} cotizacion={ruta} ref={componentRef} />
</div>
}

export default CotizacionEditar