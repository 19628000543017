import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Accordion, Badge, Button, Card, Form, Modal, Spinner, Table } from 'react-bootstrap'
import Header from '../Header'
import 'react-toastify/dist/ReactToastify.css'
import { Row, Col } from 'react-bootstrap'
import Select from 'react-select';
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import { urlapi, key_local_storage_cart, key_local_storage_user } from '../../lib/backend/data'
import { formatoMoneda, montoMoneda, showAmmount } from '../../lib/helpers/helpers'
import Inventario from '../../subComponents/repuestos/inventario'
import BuscadorCodigo from '../../subComponents/productos/buscador_codigo'
import { checkPermission } from '../../lib/helpers/session'
import AplicarDescuento from '../../subComponents/descuentos/apply'

class Home extends Component {
    constructor(){
        super()
        this.state = {
            username: '',
            password: '',
            submitted: false,
            loading: false,
            errorLogin: '',
            loadinguser: true,
            loadinginsignias: true,
            insignias: [],
            selec_marca: 'mazda',
            selec_modelo: '',
            selec_ano: '',
            selec_palabra: '',
            selec_cilindrada: '',
            resultados: [],
            loadingResultados: false,
            loadingModelos: true,
            loadingCilindrada: true,
            cilindradas: [],
            modelos: [],
            carrito: localStorage.getItem(key_local_storage_cart) ? JSON.parse(localStorage.getItem(key_local_storage_cart) ) : [],
            user: localStorage.getItem(key_local_storage_user) ? JSON.parse(localStorage.getItem(key_local_storage_user) ) : {},
            precio_producto_seleccionado: false,
            precio_producto_seleccionado_error: false,
            tipo_descuento_seleccionado: false,
            permitir_cambiar_precio_modal: true,
            loadingActualizandoProducto: false
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChangeSelectMarca = this.handleChangeSelectMarca.bind(this)
        this.handleChangeSelectModelo = this.handleChangeSelectModelo.bind(this)
        this.handleChangeSelectCilindrada = this.handleChangeSelectCilindrada.bind(this)
        this.handleChangeState = this.handleChangeState.bind(this)
        this.handleSubmitCodigo = this.handleSubmitCodigo.bind(this)
        this.handleChangeCantidad = this.handleChangeCantidad.bind(this)
        this.handleChangePrice = this.handleChangePrice.bind(this)
        this.handleChangeNotas = this.handleChangeNotas.bind(this)
    }

    componentDidMount(){
      const { user } = this.state
      const permitir = checkPermission('facturacion', user)
        if(permitir !== true) window.location = '/account'
      this.getModelos()
      this.getCilindrada()
    }

    getCilindrada(){
      const loading = 'loadingCilindrada'
      this.setState({ [loading]: true })
      const { propietario }  = this.state
      const url = `${urlapi}/productos/cilindradas`;
      this.setState({ [loading] : true })
      return fetch(url)
      .then(pros => pros.json())
      .then(pros => {
        if(!pros){
          toast.error("No hubo respuesta del servidor")
        } else if(pros.errorMessage){
          toast.error(pros.errorMessage)
        } else if(Array.isArray(pros) !== false){
          this.setState({ cilindradas: pros.map(m => ({ value: m._id, label: m.titulo })) })
        }
          this.setState({ [loading]: false })
      })
      .catch(error => {
          this.setState({ [loading]: false })
          return toast.error('No se pudo realizar esta operación')
      })
  }
    getModelos(){
      const loading = 'loadingModelos'
      this.setState({ [loading]: true })
      const url = `${urlapi}/productos/modelos`;
      this.setState({ [loading] : true })
      return fetch(url)
      .then(pros => pros.json())
      .then(pros => {
        if(!pros){
          toast.error("No hubo respuesta del servidor")
        } else if(pros.errorMessage){
          toast.error(pros.errorMessage)
        } else if(Array.isArray(pros) !== false){
          this.setState({ modelos: pros.map(m => ({ value: m._id, label: m.titulo })) })
        }
          this.setState({ [loading]: false })
      })
      .catch(error => {
          this.setState({ [loading]: false })
          return toast.error('No se pudo realizar esta operación')
      })
  }

    handleChangeNotas(e){
      const { name, value } = e.target
      const { repuesto_seleccionado } = this.state
      repuesto_seleccionado[name] = value
      return this.setState({ repuesto_seleccionado })
    }

    handleChangeState(e){
      const { name, value } = e.target
      return this.setState({ [name]: value })
    }
    
    handleChangeSelectMarca(e){
      return this.setState({ selec_marca: e.value })
    }
    
    handleChangeSelectModelo(e){
      return this.setState({ selec_modelo: e.value })
    }

    handleChangeSelectCilindrada(e){
      return this.setState({ selec_cilindrada: e.value })
    }
    
    misDatos(){
      return <Row className="bienvenida">

      
        <Col xs={12} className="mt-3"> <h5>Mis datos</h5>  </Col>

        <Col md={3}>
        <Card >
          <Card.Body>
            <p className="text-primary nomargin" >Estado del usuario</p>
            <p className="nomargin" style={{ fontSize: 12 }} >Activo</p>
          </Card.Body>
        </Card>
        </Col>
        <Col md={3}>
        <Card >
          <Card.Body>
            <p className="text-primary nomargin" >Email</p>
            <p className="nomargin" style={{ fontSize: 12 }} >Abdías Laguna</p>
          </Card.Body>
        </Card>
        </Col>

      </Row>

    }

    fetchCodigo(codigo_busqueda){
      if(!codigo_busqueda) return toast.error("Debes introducir el código de búsqueda")
      const loading = "loadingResultados"
      this.setState({ [loading]: true })
      return fetch(`${urlapi}/productos/search-code`,{
        method: "POST",
        body: JSON.stringify({
          codigo_busqueda: codigo_busqueda.toUpperCase()
        }),
        headers: {
            'Content-Type': 'application/json'
        }
      })
      .then(res => res.json())
      .then(resultados => {
        if(!resultados){
          toast.error("Sin datos del servidor")
        } else if(resultados.errorMessage){
          toast.error(resultados.errorMessage)
        } else if(Array.isArray(resultados) !== false){
          this.setState({ resultados })
      }
        return this.setState({ [loading]: false })
      })
      .catch(error => {
        console.log(error)
        return this.setState({ [loading]: false })
      })
    }

    busquedaCodigo(){
      const { codigo_busqueda } = this.state
      this.fetchCodigo(codigo_busqueda)
    }

    handleSubmit(e){
      const { selec_ano, selec_marca, selec_modelo, selec_palabra, selec_cilindrada } = this.state
      if(e) e.preventDefault()
      if(!selec_ano) return toast.error('Año es requerido')
      if(!selec_marca) return toast.error('Marca es requerido')
      if(!selec_modelo) return toast.error('Modelo es requerido')
      if(!selec_cilindrada) return toast.error('Cilindrado es requerido')
      if(!selec_palabra) return toast.error('Título es requerido')

      const loading = "loadingResultados"
      this.setState({ [loading]: true })
      return fetch(`${urlapi}/productos/search`,{
        method: "POST",
        body: JSON.stringify({
          modelo: selec_modelo,
          ano: selec_ano,
          cilindrada: selec_cilindrada,
          nombre: selec_palabra
        }),
        headers: {
            'Content-Type': 'application/json'
        }
      })
      .then(res => res.json())
      .then(resultados => {
        if(!resultados){
          toast.error("Sin datos del servidor")
        } else if(resultados.errorMessage){
          toast.error(resultados.errorMessage)
        } else if(Array.isArray(resultados) !== false){
//          setTrabajadores(res.datos)
  //        setTotal(res.total)
          this.setState({ resultados })
      }
        return this.setState({ [loading]: false })
      })
      .catch(error => {
        console.log(error)
        return this.setState({ [loading]: false })
      })
    }

    handleClose(){
      return this.setState({ repuesto_seleccionado: false, permitir_cambiar_precio_modal: true, precio_producto_seleccionado: false, tipo_descuento_seleccionado: false })
  }

    seleccionarRepuesto(repuesto_seleccionado) {
      const instancia_producto = JSON.parse( JSON.stringify(repuesto_seleccionado) )
      instancia_producto.cantidad = 1
      instancia_producto.precio_original = instancia_producto.precio
      this.setState({ repuesto_seleccionado: instancia_producto, precio_producto_seleccionado: instancia_producto.precio })
      const valor_string = formatoMoneda(instancia_producto.precio).toString()
      setTimeout(() => {
        if(document.getElementById('numero_precio')) document.getElementById('numero_precio').value = valor_string
      }, 100);
    }

    removerDeCarrito(i){
      const { carrito } = this.state
      carrito.splice(i,1)
      localStorage.setItem(key_local_storage_cart, JSON.stringify(carrito))
      return this.setState({ carrito   })
    }

    agregarCarrito(){
      const { repuesto_seleccionado, carrito, precio_producto_seleccionado_error, tipo_descuento_seleccionado } = this.state
      if(precio_producto_seleccionado_error === true ) return toast.error("Error en el precio")
      toast.success(`Agregado ${repuesto_seleccionado.titulo} exitosamente`)
      const i = carrito.findIndex(p => p._id === repuesto_seleccionado._id)
      const cantidad_modal_producto = parseInt(repuesto_seleccionado.cantidad)
      if(i > -1){
        let cantidad = cantidad_modal_producto ? cantidad_modal_producto : 1
        const total_nuevo = parseInt(carrito[i].cantidad) + cantidad
        console.log({cantidad_modal_producto, cantidad_sumar: cantidad, total_nuevo })
        carrito[i].cantidad = total_nuevo
      } else {
        if(!cantidad_modal_producto) cantidad_modal_producto = 1
        if(tipo_descuento_seleccionado) repuesto_seleccionado.descuentos = [ tipo_descuento_seleccionado ]
        carrito.push(repuesto_seleccionado)
      }
      localStorage.setItem(key_local_storage_cart, JSON.stringify(carrito))
      this.setState({ carrito, repuesto_seleccionado: false })
    }

    handleChangeCantidad(e) {
      const { value } = e.target
      const { repuesto_seleccionado } = this.state
      repuesto_seleccionado.cantidad = value
      return this.setState({ repuesto_seleccionado })
    }

    obtenerMaximoPrecio() {
      const { user } = this.state
      if(!user) return false
      if(!user.data) return false
      if(typeof user.data !== "object") return false
      if(typeof user.data.allow_rise_price_percentage === "undefined" || user.data.allow_rise_price_percentage === null) return false
      return user.data.allow_rise_price_percentage
    }

    obtenerMinimoPrecio() {
      const { user } = this.state
      if(!user) return false
      if(!user.data) return false
      if(typeof user.data !== "object") return false
      if(typeof user.data.allow_down_price_percentage === "undefined" || user.data.allow_down_price_percentage === null) return false
      return user.data.allow_down_price_percentage
    }

    obtenerMaximoMinimo(){
      const { precio_producto_seleccionado } = this.state
      if(!precio_producto_seleccionado) return false

      const anterior = precio_producto_seleccionado
      const maximo = this.obtenerMaximoPrecio()
      let monto_maximo = 0
      const minimo = this.obtenerMinimoPrecio()
      let monto_minimo = 0
      let precio_producto_seleccionado_error = false

      if(maximo !== false) monto_maximo = anterior + ((anterior*maximo)/100)
      if(minimo !== false) monto_minimo = anterior - ((anterior*minimo)/100)
      return {
        monto_maximo,
        monto_minimo
      }
    }
    
    handleChangePrice(e) {
      const { value } = e.target
      const { repuesto_seleccionado, precio_producto_seleccionado } = this.state
      
      const input = value ? value.replace(/[.,;]/g, "") : ''
      let monto = parseInt(input) 
      if(isNaN(monto)) monto = 0

      const anterior = precio_producto_seleccionado
      const maximo = this.obtenerMaximoPrecio()
      let monto_maximo = 0
      const minimo = this.obtenerMinimoPrecio()
      let monto_minimo = 0
      let precio_producto_seleccionado_error = false

      if(maximo !== false) monto_maximo = anterior + ((anterior*maximo)/100)
      if(minimo !== false) monto_minimo = anterior - ((anterior*minimo)/100)
      
      if(minimo !== false){
        if(monto < monto_minimo){
          if(!precio_producto_seleccionado_error) precio_producto_seleccionado_error = true
        }
      }
      
      if(maximo !== false){
        if(monto > monto_maximo){
          if(!precio_producto_seleccionado_error) precio_producto_seleccionado_error = true
        }
      }

      repuesto_seleccionado.precio = monto
      const valor_string = formatoMoneda(monto).toString()
      if(document.getElementById('numero_precio')) document.getElementById('numero_precio').value = valor_string
      return this.setState({ repuesto_seleccionado, precio_producto_seleccionado_error })
    }

    removerDescuento(){
      const { repuesto_seleccionado, precio_producto_seleccionado } = this.state
      repuesto_seleccionado.precio = precio_producto_seleccionado
      this.setState({ tipo_descuento_seleccionado: false, repuesto_seleccionado, permitir_cambiar_precio_modal: true })
    }

    mostrarTipoDescuento(){
      const { tipo_descuento_seleccionado, user } = this.state

      const montos = this.obtenerMaximoMinimo()

      if(tipo_descuento_seleccionado !== false){
        if(tipo_descuento_seleccionado._id){
          return <div>
            <Card className='p-3 mb-3 shadow'>
            <h6 className='mb-0'>Seleccionaste el tipo de descuento temporal</h6>
            <Row className='mb-3'>
              <Col md={3}>
                <label className='form-control-label d-block'>Tipo</label>
                <h6 className='mb-0'>{tipo_descuento_seleccionado.tipo}</h6>
              </Col>
              <Col md={3}>
                <label className='form-control-label d-block'>Valor</label>
                <h6 className='mb-0'>{formatoMoneda(tipo_descuento_seleccionado.valor)}</h6>
              </Col>
              <Col md={6}>
                <label className='form-control-label d-block'>Concepto</label>
                <h6 className='mb-0'>{tipo_descuento_seleccionado.concepto}</h6>
              </Col>
            </Row>
            <Button size='sm' variant="outline-danger" onClick={() => this.removerDescuento()} ><i class="fas fa-trash"></i> REMOVER</Button>
            </Card>
          </div>
        } else {

        }
      }

      return <Card className='p-3 mb-2 shadow'>
      {
        montos ? <div>
          <h6 className='mb-0'>DESCUENTO AUTORIZADO</h6>
          <p className='mb-0'>Precio mínimo <b>{formatoMoneda(montos.monto_minimo)}</b></p>
          <p className='mb-0'>Precio máximo <b>{formatoMoneda(montos.monto_maximo)}</b></p>
          <hr className='mb-2 mt-2'/>
        </div> : false
      }
      <h6 className='mb-0'>DESCUENTOS TEMPORALES</h6>
      <AplicarDescuento token={user.tokenSession} onSelectDescuento={(tipo_descuento_seleccionado) => {
        const { repuesto_seleccionado, precio_producto_seleccionado } = this.state
        let precio = precio_producto_seleccionado
        if(tipo_descuento_seleccionado.tipo === "porcentaje"){
            precio = precio_producto_seleccionado - Math.round(precio_producto_seleccionado * tipo_descuento_seleccionado.valor / 100)
        } else if(tipo_descuento_seleccionado.tipo === "monto_fijo"){
            precio = Math.min( precio_producto_seleccionado, Math.round( precio_producto_seleccionado - tipo_descuento_seleccionado.valor ) )
        }
        repuesto_seleccionado.precio = precio
        return this.setState({ tipo_descuento_seleccionado, permitir_cambiar_precio_modal: false })
      }} condicion_default={{ id_usuario_autorizado: user.data._id, status: "unredeemed" }} />
      </Card>
    }

    mostrarPrecio(precio){
      const { user, repuesto_seleccionado, permitir_cambiar_precio_modal } = this.state
      
      const showPriceStatic = (price) => <h2>${showAmmount(price)}</h2>
      if(!user) return showPriceStatic(precio)
      if(!user.data) return showPriceStatic(precio)
      if(typeof user.data !== "object") return showPriceStatic(precio)
      if(user.data.allow_change_price_product !== true) return showPriceStatic(precio)

      return <div>
        
        <label className='form-control-label d-block'>Precio</label>
        {
          permitir_cambiar_precio_modal ? <input id="numero_precio" style={{ 
            fontSize: 32, 
            padding: "3px 8px",
            fontWeight: "bold", 
            color: "black", 
            height: "fit-content",
            borderRadius:0,
            width: "100%",
            border: "1px solid #cecece",
            borderBottom: "2px solid black",
            marginBottom: 15 }} 
            defaultValue={precio} onChange={this.handleChangePrice} /> : <h1 className='mb-3'>{formatoMoneda(precio)}</h1>
        }
      </div>
    }

    async actualizarProducto() {
      const { repuesto_seleccionado, user } = this.state
      const loading = 'loadingActualizandoProducto'
      this.setState({ [loading]: true })
      return fetch(`${urlapi}/productos`,{
          method:'PUT',
          body: JSON.stringify({
            _id: repuesto_seleccionado._id,
            titulo: repuesto_seleccionado.titulo,
            notas: repuesto_seleccionado.notas
          }),
          headers: {
              'Content-Type':'application/json',
              'Authorization': `Bearer: ${user.tokenSession}`
          }
      })
      .then(res => {
          if(res.status === 401) return window.location = '/login'
          return res.json()
      })
      .then(res => {
          if(!res){
              toast.error('Sin datos')
          } else if(res.errorMessage){
              toast.error(res.errorMessage)
          } else if(res._id){
              toast.success('Guardado exitosamente')
          }
          return this.setState({ [loading]: false })
      })
      .catch(error => {
          toast.error(error.message)
          return this.setState({ [loading]: false })
      })
  }

    modalRepuestoSeleccionado(){
      const { repuesto_seleccionado, modelos, loadingActualizandoProducto, cilindradas, precio_producto_seleccionado_error } = this.state
      if(!repuesto_seleccionado) return false

      return <Modal size='xl' show={repuesto_seleccionado ? true : false} onHide={()=>this.handleClose()} centered >
      <Modal.Body>
        <Row>
          <Col md={12}>
            <h3>{repuesto_seleccionado.titulo ? repuesto_seleccionado.titulo.toUpperCase() : ""}</h3>
          <Row>
            <Col xs={6}><h6>CÓDIGO {repuesto_seleccionado.codigo}</h6></Col>
            <Col xs={6}><h6>CÓDIGO PROVEEDOR {repuesto_seleccionado.codigo_proveedor}</h6></Col>
          </Row>
          <Inventario id={repuesto_seleccionado.id} />
          <Row className='mb-2'>
            <Col xs={12}>
            {this.mostrarPrecio(repuesto_seleccionado.precio)}
            </Col>
            <Col xs={2}>
              <label className='form-control-label d-block'>Cantidad</label>
              <input className='form-control' type="number" defaultValue={repuesto_seleccionado.cantidad} onChange={this.handleChangeCantidad} />
            </Col>
            <Col xs={10}>
              <label className='form-control-label d-block'>Click para agregar</label>
              { precio_producto_seleccionado_error ? <Button variant='secondary' className='mb-2 w-100'><i className="text-danger fas fa-exclamation-triangle"></i> PRECIO NO AUTORIZADO</Button> : <Button variant='success' className='mb-2 w-100' onClick={() => this.agregarCarrito()}>AGREGAR AL CARRITO</Button> }
              
            </Col>
            <Col xs={12} className='mt-3'>
            <h6>Selecciona un descuento</h6>
            {this.mostrarTipoDescuento()}
            </Col>
          </Row>
          </Col>
          <Col md={12}>
            <h6>Tabla de usos</h6>
            <Table>
              <thead>
                <tr>
                  <th>NOMBRE</th>
                  <th>MODELO</th>
                  <th>CILINDRADA</th>
                  <th>ANO</th>
                </tr>
              </thead>
              <tbody>
            {
              repuesto_seleccionado.aplicaciones.map((rel,i) => {
                // MODELO
                let titulo_modelo = "No encontrado"
                const ubicacion = modelos.findIndex(m => m.value === rel.id_modelo)
                if(ubicacion > -1){
                  titulo_modelo = modelos[ubicacion].label
                }
                // CILINDRADA
                let titulo_cilindrada= "No encontrado"
                const ubicacion_c = cilindradas.findIndex(m => m.value === rel.id_cilindrada)
                if(ubicacion_c > -1){
                  titulo_cilindrada = cilindradas[ubicacion_c].label
                }
                return <tr key={`res-detalle-${repuesto_seleccionado.nombre}`}>
                <th >{repuesto_seleccionado.titulo}</th>
                <th>{titulo_modelo}</th>
                <th>{titulo_cilindrada}</th> 
                <th>{rel.ano_desde} · {rel.ano_hasta}</th>
              </tr>
              })
            }
            </tbody>
            </Table>
          </Col>
          <Col className='mt-3' md={12}>
              <label className='form-control-label d-block'>Observaciones</label>
              <textarea style={{ minHeight: 150 }} className='form-control' name="notas" defaultValue={repuesto_seleccionado.notas} onChange={this.handleChangeNotas} ></textarea>
              { loadingActualizandoProducto ? <Spinner animation='border' /> : <Button className='w-100' variant='outline-primary' onClick={() => this.actualizarProducto()}>GUARDAR CAMBIOS</Button>}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  }

    tablaRegistros(registros){
      return  <Table className='mb-0'>
      <thead>
        <tr>
          <th>DESCRIPCIÓN</th>
          <th>CÓDIGO</th>
          <th>CÓDIGO PROVEEDOR</th>
          <th>APLICACIONES</th>
          <th>PRECIO</th>
        </tr>
      </thead>
      <tbody>
        {
          registros.map((res,ir) => {
            return <tr className='hover' key={`res${res.titulo}-${res.codigo_proveedor}`} onClick={() => this.seleccionarRepuesto(res)}>
              <th>{res.titulo}</th>
              <th>{res.codigo}</th>
              <th>{res.codigo_proveedor}</th>
              <th>{res.aplicaciones.length}</th>
              <th>{formatoMoneda(res.precio)}</th>
            </tr>
          })
        }
      </tbody>
    </Table>
    }

    cargando(){
      return <div className='mt-4'>
        <Row>
          <Col md={3} className="mb-3"><Skeleton height={20} /></Col>
          <Col md={3} className="mb-3"><Skeleton height={20} /></Col>
          <Col md={3} className="mb-3"><Skeleton height={20} /></Col>
          <Col md={3} className="mb-3"><Skeleton height={20} /></Col>
          <Col md={3} className="mb-3"><Skeleton height={20} /></Col>
          <Col md={3} className="mb-3"><Skeleton height={20} /></Col>
          <Col md={3} className="mb-3"><Skeleton height={20} /></Col>
          <Col md={3} className="mb-3"><Skeleton height={20} /></Col>
          <Col md={3} className="mb-3"><Skeleton height={20} /></Col>
          <Col md={3} className="mb-3"><Skeleton height={20} /></Col>
          <Col md={3} className="mb-3"><Skeleton height={20} /></Col>
          <Col md={3} className="mb-3"><Skeleton height={20} /></Col>
          <Col md={3} className="mb-3"><Skeleton height={20} /></Col>
          <Col md={3} className="mb-3"><Skeleton height={20} /></Col>
          <Col md={3} className="mb-3"><Skeleton height={20} /></Col>
          <Col md={3} className="mb-3"><Skeleton height={20} /></Col>
          <Col md={3} className="mb-3"><Skeleton height={20} /></Col>
          <Col md={3} className="mb-3"><Skeleton height={20} /></Col>
          <Col md={3} className="mb-3"><Skeleton height={20} /></Col>
          <Col md={3} className="mb-3"><Skeleton height={20} /></Col>
          <Col md={3} className="mb-3"><Skeleton height={20} /></Col>
          <Col md={3} className="mb-3"><Skeleton height={20} /></Col>
          <Col md={3} className="mb-3"><Skeleton height={20} /></Col>
          <Col md={3} className="mb-3"><Skeleton height={20} /></Col>
        </Row>
      </div>
    }

    mostrarResultados(){
      const { loadingResultados, resultados } = this.state
      if(loadingResultados) return this.cargando()
      if(resultados.length < 1) return <div >
        <h5 className='mb-0'>Sin resultados</h5>
      </div>

      return <div >
        <h5>{resultados.length} resultados</h5>
        {this.tablaRegistros(resultados)}
      </div>
    }

    onChangeCarrito(carrito) {
      this.setState({ carrito })
      localStorage.setItem(key_local_storage_cart,JSON.stringify(carrito))
      return false
    }

    onProductRemoved(pos){
      const { carrito } = this.state
      carrito.splice(pos,1)
      this.setState({ carrito })
      localStorage.setItem(key_local_storage_cart,JSON.stringify(carrito))
      return false
    }

    cleanCart(pos){
      this.setState({ carrito: [] })
      localStorage.setItem(key_local_storage_cart,JSON.stringify([]))
      return false
    }

    handleSubmitCodigo(e) {
      e.preventDefault()
      const { codigo_busqueda } = this.state
      return this.fetchCodigo(codigo_busqueda)
    }

    onResultados(data){
      return this.setState({ resultados: data })
    }
    
    onSelectProduct(data){
      return this.setState({ resultados: [data] })
    }

    render(){
      const { modelos, loadingModelos, carrito, cilindradas, loadingCilindrada } = this.state
        return(
            <div className="fluid">
              <Header carrito={carrito} 
                  onProductRemoved={(pos) => this.onProductRemoved(pos)} 
                  onChangeCarrito={(new_cart) => this.onChangeCarrito(new_cart)} 
                  cleanCart={() => this.cleanCart()}
                />
          <div className="container-fluid">
            <div className="row">
              <main className="col-md-12 ms-sm-auto col-lg-12 px-md-4 leftauto">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                  <h1 className="h2">Facturación</h1>
                </div>

                <h3 style={{ fontWeight:100}}>Buscar repuestos</h3>

                
                <Row>
                  <Col md={3}>
                    <h4>Modelo</h4>
                    <Select
                                      style={{ marginBottom: 10 }}
                                      onChange={this.handleChangeSelectModelo}
                                      options={modelos}
                                      isLoading={loadingModelos}
                                      defaultValue={''}
                                      placeholder="Modelo..."
                    />
                  </Col>
                  <Col md={3}>
                    <h4>Cilindrada</h4>
                    <Select
                                      style={{ marginBottom: 10 }}
                                      onChange={this.handleChangeSelectCilindrada}
                                      options={cilindradas}
                                      isLoading={loadingCilindrada}
                                      defaultValue={''}
                                      placeholder="Cilindrada..."
                    />
                  </Col>
                  <Col md={2}>
                    <h4>Año</h4>
                    <input className='form-control' name="selec_ano" type="number" min="1900" max="2099" step="1" onChange={this.handleChangeState} />
                  </Col>
                  <Col md={2}>
                  <form onSubmit={this.handleSubmit}>
                    <h4>Título</h4>
                    <input className='form-control' name="selec_palabra" onChange={this.handleChangeState} />
                  </form>
                  </Col>
                  <Col md={2}>
                    <h4>Buscar</h4>
                    <Button onClick={()=>this.handleSubmit(false)}><i className="fas fa-search"></i> BUSCAR</Button>
                  </Col>
                </Row>
                

            <hr />

            <form onSubmit={this.handleSubmitCodigo}>
              <Card className='p-5'>
                <Row className='justify-content-md-center'>
                  <Col md={2} className='text-center'>
                    <img src="images/search.png" style={{ width: "100%" }}/>
                  </Col>
                  <Col md={2}>
                    <h4>Código</h4>
                    <BuscadorCodigo 
                      onChange={(data) => this.setState({ codigo_busqueda: data})} 
                      tipo="funcion" 
                      onSelectProduct={data => this.onSelectProduct(data)} 
                      onResultados={(data) => this.onResultados(data)}
                    />
                  </Col>
                  <Col md={2}>
                    <h4>Buscar</h4>
                    <Button className='w-100' onClick={()=>this.busquedaCodigo()}><i className="fas fa-search"></i> BUSCAR CÓDIGO</Button>
                  </Col>
                </Row>
                </Card>
            </form>

            <hr />
              {this.modalRepuestoSeleccionado()}
              <Card className='p-3'>
              {this.mostrarResultados()}
              </Card>
              </main>
            </div>
          </div>
    </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(Home);