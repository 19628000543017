import { useState } from "react"
import { Button, Col, Modal, Row, Spinner } from "react-bootstrap"
import { toast } from "react-toastify"
import { key_local_storage_user, urlapi } from "../../lib/backend/data"
import { formatoRut, validarRut, validateEmail } from "../../lib/helpers/helpers"
const { validateRut } = require("@fdograph/rut-utilities");

const CrearEmpresa = (props) => {
    const user = JSON.parse(localStorage.getItem(key_local_storage_user))
    const [openModal, setOpenModal] = useState(false)
    const [empresa, setEmpresa] = useState({})
    const [loading, setLoading] = useState(false)


    const handleClose = () => {
        setOpenModal(false)
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        empresa[name] = value
        return setEmpresa(prev => ({...{}, ...empresa}))
    }

    const crearCliente = () => {
        const requeridos = [
            { value:"vatId", label: "Rut" },
            { value:"name", label: "Nombre" },
            { value:"addressBilling", label: "Dirección" },
            { value:"cityBilling", label: "Ciudad" },
            { value:"countyBilling", label: "Comuna" },
            { value:"activityName", label: "Giro" },
            { value:"email", label: "Email" },
        ]
        let faltantes = []
        requeridos.map(campo => {
            if(!empresa[campo.value]) faltantes.push(campo.label)
            return true
        })
        if(faltantes.length > 0) return toast.error(`Faltan campos: ${faltantes.join(', ')}`)
        if(!validateEmail(empresa.email)) return toast.error(`Email inválido`)
        empresa.legalName = empresa.name
        if(!validateRut(empresa.vatId)) return toast.error("Rut inválido")
        const rut = formatoRut(empresa.vatId)
        empresa.vatId = rut
        empresa.DTEEmail = empresa.email
        setLoading(true)
        return fetch(`${urlapi}/clients`,{
            method:'POST',
            body: JSON.stringify(empresa),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            }
            })
            .then(res => {
                if(res.status === 401) return window.location = '/login'
                return res.json()
            })
            .then(res => {
                console.log(res)
                if(!res){
                    toast.error('Sin datos')
                } else if(res.errorMessage){
                    toast.error(res.errorMessage)
                } else if(res.customerId){
                    toast.success("Creado exitosamente")
                    if(props.onCreatedNewCliente){
                        props.onCreatedNewCliente(res)
                        handleClose()
                    }
                }
                return setLoading(false)
            })
            .catch(error => {
                toast.error("Error al consultar métodos de pago, intente nuevamente")
                return setLoading(false)
            })
    }

    return <div>
        <p className='mb-0 text-primary hover' onClick={() => setOpenModal(true)}><b>CREAR CLIENTE NUEVO</b></p>
        <Modal show={openModal} size="lg" onHide={()=>handleClose()} centered  >
            <Modal.Header closeButton><h3 className="mb-0">Crear cliente nuevo</h3></Modal.Header>
            <Modal.Body>
                
                <Row>
                    <Col md={12} className="mb-3">
                        <label className="form-control-label">Nombre</label>
                        <input className="form-control" name="name" onChange={handleChange} />
                    </Col>
                    <Col md={12} className="mb-3">
                        <label className="form-control-label">Rut</label>
                        <input className="form-control" name="vatId" onChange={handleChange} />
                    </Col>
                    <Col md={12} className="mb-3">
                        <label className="form-control-label">Dirección</label>
                        <input className="form-control" name="addressBilling" onChange={handleChange} />
                    </Col>
                    <Col md={12} className="mb-3">
                        <label className="form-control-label">Ciudad</label>
                        <input className="form-control" name="cityBilling" onChange={handleChange} />
                    </Col>
                    <Col md={12} className="mb-3">
                        <label className="form-control-label">Comuna</label>
                        <input className="form-control" name="countyBilling" onChange={handleChange} />
                    </Col>
                    <Col md={12} className="mb-3">
                        <label className="form-control-label">Giro</label>
                        <input className="form-control" name="activityName" onChange={handleChange} />
                    </Col>
                    <Col md={12} className="mb-3">
                        <label className="form-control-label">Email</label>
                        <input className="form-control" name="email" onChange={handleChange} />
                    </Col>
                    <Col md={12} className="mb-3">
                        { loading ? <Spinner animation="border" /> : <Button variant="success" onClick={() => crearCliente()}>CREAR CLIENTE</Button> }
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    </div>
}

export default CrearEmpresa